<script lang="ts" setup>
const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();
const { t } = useT();
const { insertGA } = useGA({ autoLoad: false });
const { cookiesEnabledIds } = useCookieControl();
const { checkDeviceId } = usePopupAds();

const isCookiesNoticeEnabled = computed(() => appInitData.value?.isCookiesNoticeEnabled);

let stopWatch: () => void = () => {};

const fireAnalytics = () => {
	checkDeviceId();
	insertGA();
	stopWatch();
};

stopWatch = watchEffect(() => {
	if (cookiesEnabledIds?.value?.includes("analytics") || !isGuest.value || !isCookiesNoticeEnabled.value) {
		fireAnalytics();
	}
});

if (!isGuest.value) {
	stopWatch();
}

onUnmounted(() => {
	stopWatch();
});
</script>
<template>
	<CookieControl v-if="isGuest && isCookiesNoticeEnabled" class="cookie-wrapper">
		<template #bar>
			<AText variant="tempe" class="text-clara">
				{{
					t(
						"This website uses cookies to enhance user experience and to analyze performance and traffic on our website.\n We also share information about your use of our site with our social media, advertising and analytics partners"
					)
				}}
			</AText>
			<br />
			<NuxtLink target="_blank" to="/page/cookie-notice">
				<AText :modifiers="['underline']" class="text-сirebon">{{ t("More About Cookie Notice") }}</AText>
			</NuxtLink>
		</template>
	</CookieControl>
</template>

<style lang="scss">
.cookieControl {
	.cookieControl__Bar {
		font-family: var(--font-family);
		background: var(--carletonville);
		border: 2px solid var(--cracow);
		max-width: 1312px;
		width: calc(100% - 32px);
		display: flex;
		justify-self: center;
		border-radius: 8px;
		padding: 8px 24px;
		margin: 0 auto 25px;

		@include media-breakpoint-down(lg) {
			width: calc(100% - 32px);
			margin: 0 auto 115px;
			padding: 8px 16px;
		}
	}

	.cookieControl__BarContainer {
		padding: 0;
		gap: 16px;
		align-items: center;

		@include media-breakpoint-down(lg) {
			gap: 0;
		}
	}

	.cookieControl__ControlButton {
		display: none;
	}

	.cookieControl__BarButtons {
		button {
			height: 48px;
			border-radius: 12px;
			padding: 0 16px;
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			line-height: 1;

			&:nth-child(1) {
				color: var(--a-button-primary-color);
				background: var(--a-button-primary-background);
				box-shadow: var(--a-button-primary-box-shadow);

				&:hover {
					box-shadow: none;
					background: var(--a-button-primary-hover-background);
				}
			}
			&:nth-child(2) {
				color: var(--a-button-secondary-color);
				background: var(--a-button-secondary-background);
				border: 2px solid var(--a-button-secondary-border-color);

				&:hover {
					color: var(--a-button-secondary-hover-color);
					background: var(--a-button-secondary-hover-background);
					border: 2px solid var(--a-button-secondary-hover-border-color);
				}
			}
			&:nth-child(3) {
				display: none;
			}

			@include media-breakpoint-down(md) {
				width: 100%;

				&:nth-child(1) {
					margin-top: 0;
				}

				&:nth-child(2) {
					margin-top: 12px;
				}
			}
		}
	}
}
</style>
